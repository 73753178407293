<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="100"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import moment from 'moment'
export default {
  name: "Form",
  data() {
    return {
      initData: {
        entryTime: [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      formItemList: [
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间:",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          clearable: true,
        },
        {
          key: "merchantName",
          type: "input",
          lableWidth:'100px',
          labelName: "回收商名称:",
          placeholder: "请输入商家名称查询",
        },
        {
          key: "contactPhone",
          isNumber: true,
          type: "input",
          labelName: "联系电话:",
          placeholder: "请输入联系人电话查询",
        },
      ],
      formRules: {
        phone: [
          {
            validator: formValidateMobile,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.handleConfirm(this.initData);
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {};
      if (data) {
        SeachParams = {
          contactPhone: data.contactPhone || "",
          merchantName: data.merchantName || "",
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams. disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
