<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
            <div :class="auditStatus=== '00'? 'active' : 'none'" @click="btnClick('00')">
              全部({{refundNumber.allCount}})
            </div>
            <div :class="auditStatus=== '01'? 'active' : 'none'" @click="btnClick('01')">
              待审核<span :class="auditStatus!== '01' && refundNumber.toAuditCount>0?'tipColor':''">({{refundNumber.toAuditCount}})</span>
            </div>
            <div :class="auditStatus=== '02'? 'active' : 'none'" @click="btnClick('02')">
              待退款({{refundNumber.toRefundCount}})
            </div>
            <div :class="auditStatus=== '03'? 'active' : 'none'" @click="btnClick('03')">
              已退款({{refundNumber.refundCount}})
            </div>
            <div :class="auditStatus=== '04'? 'active' : 'none'" @click="btnClick('04')">
              已驳回({{refundNumber.refuseAuditCount}})
            </div>
        </div>
      </div>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >   
        <el-table-column
            label="详细地址"
            width="130rpx"
            slot="merchantAddress"
            align="center"
        >
            <template slot-scope="{ row }">
            <el-tooltip
                class="item"
                effect="dark"
                :content="row.merchantAddress"
                placement="top"
            >
                <div class="Remarks">
                {{ row.merchantAddress }}
                </div>
            </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column
          label="退款原因"
          width="130rpx"
          slot="applyNote"
          align="center"
        >
          <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            :content="row.applyNote"
            placement="top"
          >
            <div class="Remarks">
            {{ row.applyNote }}
            </div>
          </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="收款信息" slot="info" align="center">
          <template slot-scope="{row}">
            <span @click="seeInfo(row,'left')"><span style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">查看</span></span>
          </template>
        </el-table-column>
        <el-table-column label="授权凭证" slot="info" align="center">
          <template slot-scope="{row}">
            <span @click="seeInfo(row,'right')"><span style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">查看</span></span>
          </template>
        </el-table-column>
        <el-table-column label="处理状态" slot="operation" align="center">
          <template slot-scope="{ row }">
              <span style="color:#FF687B" v-if="row.auditStatus=='01'">待审核</span>
              <span style="color:#74C259" v-if="row.auditStatus=='02'">待退款</span>
              <span style="color:#333333" v-if="row.auditStatus=='03'">已退款</span>
              <span style="color:#FBA516" v-if="row.auditStatus=='04'">已驳回</span>
          </template>
        </el-table-column>
        <el-table-column
          label="备注说明"
          width="130rpx"
          slot="operation"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              v-if="row.masterNote"
              class="item"
              effect="dark"
              :content="row.masterNote"
              placement="top"
            >
              <div class="Remarks">
              {{ row.masterNote }}
              </div>
            </el-tooltip>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column width="290" label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
                <el-button size="mini" v-if="row.auditStatus!='01' && row.auditStatus!='04'" :type="row.auditStatus=='02'?'danger':'primary'" round @click="handleBtn(row,'pay')">{{row.auditStatus=='02'?'打款':'打款记录'}}</el-button>
                <el-button size="mini" :type="row.auditStatus=='01'?'warning':'primary'" round @click="handleBtn(row,'verify')">{{row.auditStatus=='01'?'审核':'审核记录'}}</el-button>
                <el-button size="mini" type="success" round @click="handleBtn(row,'remark')">{{row.masterNote?'修改备注':'添加备注'}}</el-button>
            </template>
        </el-table-column>
    </GlobalTable>
    <!-- 备注弹出框 -->
    <el-dialog
      class="acc_dialog"
      :title='RemarksTitle'
      :visible.sync="RemarksShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="remarksClosed"
    >
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input
        maxlength="100"
        type="textarea"
        :rows="4"
        placeholder="请输入备注内容,最多100字"
        v-model="masterNote"
        show-word-limit
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="remarksSure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title='infoTitle'
      :visible.sync="infoShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="infoClosed"
    >
      <div v-if="infoType == 'left'">
        <div class="verify-title"><span>收款信息</span></div>
        <div class="mb-20" style="font-size:14px;color:#333333">
          <div class="mb-10"><span>开户银行：</span><span style="color:#666666;">{{collectionInfo.bankName}}</span></div>
          <div class="mb-10"><span>支行名称：</span><span style="color:#666666;">{{collectionInfo.branchName}}</span></div>
          <div class="mb-10"><span>卡号：</span><span style="color:#666666;">{{collectionInfo.bankCode}}</span></div>
          <div class="mb-10"><span>姓名：</span><span style="color:#666666;">{{collectionInfo.name}}</span></div>
        </div>
      </div>
      <div v-else>
        <div class="verify-title"><span>授权凭证</span><span style="color:#666666;font-size:12px">（点击图片可全屏查看）</span></div>
        <div class="demo-image__preview mb-10 verify-image-box">
          <div v-if="unitLetterUrl && unitLetterUrl.length">
            <div>账户余额退款申请函</div>
            <el-image
              v-for="(item,index) in unitLetterUrl"
              :key="index"
              style="width: 120px; height: 120px; margin-right: 20px;border-radius: 10px;"
              :src="item"
              :preview-src-list="unitLetterUrl">
            </el-image>
          </div>
          <div v-if="earnestRefundLetterUrl && earnestRefundLetterUrl.length">
            <div>保证金退款申请函</div>
            <el-image
              v-for="(item,index) in earnestRefundLetterUrl"
              :key="index"
              style="width: 120px; height: 120px; margin-right: 20px;border-radius: 10px;"
              :src="item"
              :preview-src-list="earnestRefundLetterUrl">
            </el-image>
          </div>
          <div v-if="accountChangeLetterUrl && accountChangeLetterUrl.length">
            <div>账户变更授权函</div>
            <el-image
              v-for="(item,index) in accountChangeLetterUrl"
              :key="index"
              style="width: 120px; height: 120px; margin-right: 20px;border-radius: 10px;"
              :src="item"
              :preview-src-list="accountChangeLetterUrl">
            </el-image>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="458px"
      @closed="handleDialogClosed"
    >
      <div class="verify-title" style="display: flex;justify-content: space-between;"><span>商家信息</span><div style="color:#FF8080">申请退款：￥{{refundAmount}}</div></div>
      <div style="font-size:14px;color:#333333">
        <div class="mb-10"><span style="margin-bottom: 10px;">商家名称：</span><span style="color:#666666;">{{merchantInfo.merchantName}}</span></div>
        <div class="mb-10"><span>联系人：</span><span style="color:#666666;">{{merchantInfo.applyStaffName}}</span><span style="margin-left:20px">联系电话：</span><span style="color:#666666;">{{merchantInfo.applyStaffPhone}}</span></div>
      </div>
      <div class="verify-title"><span>收款信息</span></div>
      <div style="font-size:14px;color:#333333">
        <div class="mb-10"><span>开户银行：</span><span style="color:#666666;">{{collectionInfo.bankName}}</span></div>
        <div class="mb-10"><span>支行名称：</span><span style="color:#666666;">{{collectionInfo.branchName}}</span></div>
        <div class="mb-10"><span>卡号：</span><span style="color:#666666;">{{collectionInfo.bankCode}}</span></div>
        <div class="mb-10"><span>姓名：</span><span style="color:#666666;">{{collectionInfo.name}}</span></div>
      </div>
      <div class="verify-title" v-if="btnType=='pay'"><span>打款凭证</span><span style="color:#666666;font-size:12px">{{verifyRecord?'（点击可放大查看）':'（必传，限2张图片）'}}</span></div>
      <div class="apply-letter mb-10" v-if="btnType=='pay' && !verifyRecord">
				<ElImgUploadGroup
					:length="2"
					:exist-img-list="remitVoucher"
					@handleChange="handleUploadGroupChange($event)"
				/>
      </div>
      <div class="demo-image__preview mb-10" v-if="btnType=='pay' && verifyRecord">
        <el-image
          v-for="(item,index) in remitVoucher"
          :key="index"
          style="width: 120px; height: 120px; margin-right: 10px;border-radius: 10px;"
          :src="item"
          :preview-src-list="remitVoucher">
        </el-image>
      </div>
      <div class="verify-title" v-if="btnType=='verify'"><span>授权凭证</span><span style="color:#666666;font-size:12px">（点击可放大查看）</span></div>
      <!-- <div class="demo-image__preview mb-10" v-if="btnType=='verify'>
        <el-image
          v-for="(item,index) in unitLetterUrl"
          :key="index"
          style="width: 120px; height: 120px; margin-right: 10px;border-radius: 10px;"
          :src="item"
          :preview-src-list="unitLetterUrl">
        </el-image>
      </div> -->
      <div v-if="btnType=='verify'" class="demo-image__preview mb-10 verify-image-box">
        <div v-if="unitLetterUrl && unitLetterUrl.length">
          <div>账户余额退款申请函</div>
          <el-image
            v-for="(item,index) in unitLetterUrl"
            :key="index"
            style="width: 120px; height: 120px; margin-right: 20px;border-radius: 10px;"
            :src="item"
            :preview-src-list="unitLetterUrl">
          </el-image>
        </div>
        <div v-if="earnestRefundLetterUrl && earnestRefundLetterUrl.length">
          <div>保证金退款申请函</div>
          <el-image
            v-for="(item,index) in earnestRefundLetterUrl"
            :key="index"
            style="width: 120px; height: 120px; margin-right: 20px;border-radius: 10px;"
            :src="item"
            :preview-src-list="earnestRefundLetterUrl">
          </el-image>
        </div>
        <div v-if="accountChangeLetterUrl && accountChangeLetterUrl.length">
          <div>账户变更授权函</div>
          <el-image
            v-for="(item,index) in accountChangeLetterUrl"
            :key="index"
            style="width: 120px; height: 120px; margin-right: 20px;border-radius: 10px;"
            :src="item"
            :preview-src-list="accountChangeLetterUrl">
          </el-image>
        </div>
      </div>
      <div v-if="btnType=='verify'">
        <div class="verify-title"><span>审核结果</span></div>
        <div class="mb-10" v-if="!verifyRecord">
          <el-radio v-model="auditStatusRadio" label="02">审核通过</el-radio>
          <el-radio v-model="auditStatusRadio" label="04">审核驳回</el-radio>
        </div>
        <div v-if="verifyRecord" class="mb-10" style="color:#333333">{{auditStatusRadio=='04'?'已驳回': '已通过审核'}}</div>
        <div v-if="verifyRecord" class="mb-10" style="color:#333333">{{auditStatusRadio=='04'?'驳回原因': '审核备注'}}：<span style="color:#666666;">{{auditNote || '无'}}</span></div>
        <div v-if="!verifyRecord" class="mb-10">
          <el-input maxlength="100" type="textarea" :placeholder="auditStatusRadio=='04'?'请输入驳回原因（必填，驳回原因将反馈显示给门店商户）':'审核备注（非必填）'" show-word-limit v-model="auditNote"></el-input>
        </div>
      </div>
      <div v-if="btnType=='pay'">
        <div class="verify-title"><span>打款备注</span></div>
        <div v-if="!verifyRecord" class="mb-10">
          <el-input maxlength="100" type="textarea" placeholder="请输入备注说明（非必填）" show-word-limit v-model="auditNote"></el-input>
        </div>
        <div class="mb-10" v-if="verifyRecord">{{auditNote || '无'}}</div>
      </div>
      <div class="verify-title" v-if="verifyRecord"><span>日志记录</span></div>
      <div v-if="verifyRecord && btnType=='verify'"><span>审核人：</span><span style="color:#666666;">{{auditLog.auditMasterName}}</span><span style="margin-left:20px">审核时间：</span><span style="color:#666666;">{{auditLog.auditTime}}</span></div>
      <div v-if="verifyRecord && btnType=='pay'"><span>打款人：</span><span style="color:#666666;">{{auditLog.remitMasterName}}</span><span style="margin-left:20px">打款时间：</span><span style="color:#666666;">{{auditLog.remitTime}}</span></div>
      <div class="verify-title" v-if="!verifyRecord && btnType=='pay'"><span>验证授权</span></div>
      <div v-if="!verifyRecord && btnType=='pay'">
        <el-input
          v-model="command"
          size="small"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleClose">关闭</el-button>
        <el-button type="primary" v-if="!verifyRecord" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImgUploadGroup from '../../../components/global/components/elements/upload-group-element.vue'
export default {
  name: "Table",
  components: { ElImgUploadGroup },
  data() {
    return {
      loading: false,
      seachDataList: [],
      auditStatus: '00',
      refundAmount: "",
      RemarksTitle: "",
      RemarksShow: false,
      masterNote: "",
      butLoading: false,
      rowId: "",
      refundNumber: {},
      command: "",
      auditStatusRadio: "02",
      auditNote: "",
      btnType: "",
      infoTitle: "",
      infoType: "",
      infoShow: false,
      verifyRecord: false,
      auditLog: {},
      merchantInfo: {},
      collectionInfo: {},
      unitLetterUrl: [],
      earnestRefundLetterUrl: [],
      accountChangeLetterUrl: [],
      remitVoucher: [],
      page:{
        pageNum:1,
        pageSize:10
      },
      pageNum:1,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "审核", // 标题
      dialogWidth: 380,
      tableColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { slotName: "merchantAddress" },
        { label: "联系人", prop: "applyStaffName" },
        { label: "联系电话", prop: "applyStaffPhone" },
        { slotName: "applyNote" },
        { label: "申请金额", prop: "applyBalance" },
        { label: "账户余额", prop: "balance" },
        { slotName: "info" },
        { label: "申请时间", prop: "applyTime" },
        { slotName: "operation" }
      ],
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
  },
watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      console.log(this.SeachParams);
      const baseRequest = {
        auditStatus: this.auditStatus,
        contactPhone: this.SeachParams.contactPhone,
        merchantName: this.SeachParams.merchantName,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.refundList(baseRequest).then(res => {
        if (res.code === 1) {
          
          this.seachDataList = res.data.page.records;
          this.refundNumber = res.data.applyCount
          this.page.total = res.data.page.total;
          this.page.pageNum = res.data.page.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 弹出备注弹框
    handleBtn(row,type){
      console.log(row);
      this.rowId = row.id
      this.btnType = type
      this.refundAmount= row.applyBalance
      if(type == 'remark'){
        if(row.masterNote){
            this.RemarksTitle = '修改备注'
        }else{
            this.RemarksTitle = '添加备注'
        }
        this.masterNote = row.masterNote
        this.RemarksShow = true
      }else{
        this.merchantInfo = {
          merchantName: row.merchantName,
          applyStaffPhone: row.applyStaffPhone,
          applyStaffName: row.applyStaffName
        }
        this.collectionInfo = {
          bankName: row.bankName,
          branchName: row.branchName,
          bankCode: row.bankCode,
          name: row.name
        }
        if(type == 'verify'){
          this.unitLetterUrl = row.unitLetterUrl
          this.earnestRefundLetterUrl = row.earnestRefundLetterUrl
          this.accountChangeLetterUrl = row.accountChangeLetterUrl
          if(row.auditStatus == '01'){
            this.dialogTitle = '审核'
            this.verifyRecord = false
          }else{
            this.verifyRecord = true
            this.dialogTitle = '审核记录'
            this.auditStatusRadio = row.auditStatus
            this.auditNote = row.auditNote || ''
            this.auditLog = {
              auditMasterName: row.auditMasterName,
              auditTime: row.auditTime
            }
          }
        }else{
          if(row.auditStatus == '02'){
            this.dialogTitle = '打款'
            this.verifyRecord = false
          }else{
            this.verifyRecord = true
            this.dialogTitle = '打款记录'
            this.auditNote = row.remitNote || ''
            this.remitVoucher	 = row.remitVoucher
            this.auditLog = {
              remitMasterName: row.remitMasterName,
              remitTime: row.remitTime
            }
          }
        }
        
        this.dialogVisible = true
      }
    },
    seeInfo(row,type) {
      this.infoType = type
      if(type == 'left'){
        this.infoTitle = '查看收款信息-' + row.merchantName
        this.collectionInfo = {
          bankName: row.bankName,
          branchName: row.branchName,
          bankCode: row.bankCode,
          name: row.name
        }
      }else{
        console.log(row);
        this.unitLetterUrl = row.unitLetterUrl
        this.earnestRefundLetterUrl = row.earnestRefundLetterUrl
        this.accountChangeLetterUrl = row.accountChangeLetterUrl
        this.infoTitle = '查看授权凭证-' + row.merchantName
      }
      this.infoShow = true
    },
    infoClosed() {
      this.unitLetterUrl = []
      this.earnestRefundLetterUrl = []
      this.accountChangeLetterUrl = []
      this.collectionInfo = {}
      this.infoShow = false
    },
    // 关闭备注弹框
    remarksClosed() {
        this.RemarksShow = false
        this.masterNote = ""
    },
    // 备注确定提交
    remarksSure() {
        this.butLoading = true
        _api.refundMasterNote({id:this.rowId,masterNote:this.masterNote}).then(res => {
            if (res.code === 1) {
                this.$message.success(res.msg)
                this.RemarksShow = false
                this.handleCurrentChange()
            }
            this.butLoading = false
        }).catch(err => {
            this.butLoading = false
        })
    },
    handleDialogClosed() {
      this.dialogVisible = false
      this.unitLetterUrl = []
      this.earnestRefundLetterUrl = []
      this.accountChangeLetterUrl = []
      this.remitVoucher = []
      this.auditNote = ""
      this.command = ""
    },
    handleClose() {
      this.dialogVisible = false
    },
    btnClick(type){
      this.auditStatus = type
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
    // 上传图片后，将图片信息保存到对应参数中
		handleUploadGroupChange (list) {
			if (Array.isArray(list)) {
        this.remitVoucher = list
			}
		},
    // 审核确定
    handleSuccess() {
      let params = {
        auditNote: this.auditNote,
        command: this.command,
        id: this.rowId,
      }
      if(this.btnType == 'verify'){
        params.auditStatus = this.auditStatusRadio
        if(this.auditStatusRadio == '04' && !this.auditNote){
          this.$message.error('请输入驳回原因')
          return false
        }
      }else if(this.btnType == 'pay'){
        params.auditStatus = '03'
        if(!this.remitVoucher.length){
          this.$message.error('请上传打款凭据')
          return false
        }
        if(!this.command){
          this.$message.error('请输入谷歌动态验证码')
          return false
        }
        params.remitVoucher = this.remitVoucher
      }
      _api.refundAudit(params).then(res => {
        if(res.code === 1){
          this.dialogVisible = false
          this.$message.success(res.msg || '打款成功')
          this.handleCurrentChange()
        }
      })
    },

  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .button_top{
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    width: 432px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    .active{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }
    .none{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
    // 备注说明
    .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
    }
    .command{
        display: flex;
        align-items: center;
        /deep/.el-input{
            width: 300px;
        }
    }
    .verify-title{
      color: #0981FF;
      line-height: 16px;
      font-size: 16px;
      padding-left: 10px;
      border-left: 3px solid;
      margin-bottom: 10px;
    }
    .verify-image-box{
      display: flex;
    }
    .id-img{
      display: flex;
      margin-bottom: 10px;
    }
    .id-tip{
      color: #ffffff;
      font-size: 12px;
      padding: 0 7px;
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      width: max-content;
      height: 16px;
      background: #0981FF;
      border-radius: 0px 10px 0px 10px;
    }
    .mb-10{
      margin-bottom: 10px;
    }
    .mb-20{
      margin-bottom: 20px;
    }
    /deep/.el-img-upload .el-upload{
      border: 1px dashed #C1D1FF;
      border-radius: 10px;
    }
    /deep/.el-img-upload-group .el-upload{
      border: 1px dashed #C1D1FF;
      border-radius: 10px;
    }
    .apply-letter{
      margin-bottom: 15px;
      /deep/.el-img-upload{
        margin-right: 10px;
      }
    }
    .tipColor{
      color: #FF687B;
    }
}
</style>
